import React from "react";
import "./Home.css";
import { translate, Translate } from "react-i18nify";
import { Avatar, Button, Divider } from "@mui/material";
import { EducationStepper } from "../EducationStepper/EducationStepper";
import { AboutMe } from "../AboutMe/AboutMe";
import { Experience } from "../Experience/Experience";
import { NavigateFunction, useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";

export const Home: React.FC<any> = () => {
  const navigate: NavigateFunction = useNavigate();
  const navigateToUrl = (): void => navigate("/contact-me");

  return (
    <div className="Home">
      {/* displayed on small resolution ONLY */}

      <div className="Home--container small-res">
        <Avatar
          className={"avatar"}
          alt={translate("full-name")}
          src="/images/alla-nad.jpg"
        >
          <Translate value="full-name" />
        </Avatar>
        <h3 className={"full-name"}>
          <Translate value="full-name" />
        </h3>
        <h4 className={"role-description"}>
          <Translate value={"role"} />
        </h4>
        <Button className={"contact-btn"} onClick={navigateToUrl}>
          <EmailIcon className={"btn-icon"} />
          <Translate value="contact-me" />
        </Button>
      </div>
      <Divider className={"divider small-res"} />

      {/* --------- */}

      <section className="Home--container">
        <h1 className={"uppercase"}>
          <Translate value="about-me.title" />
        </h1>
        <AboutMe />
      </section>
      <Divider className={"divider"} />
      <section className="Home--container h-p-10">
        <h1 className={"uppercase"}>
          <Translate value="education.title" />
        </h1>
        <EducationStepper />
      </section>
      {/*<Divider className={'divider'} />*/}
      {/*<div className="Home--container">*/}
      {/*    <h1 className={'uppercase'}>*/}
      {/*        <Translate value="experience.title" />*/}
      {/*    </h1>*/}
      {/*    <Experience />*/}
      {/*</div>*/}
      {/*<Divider className={'divider'} />*/}
      {/*<div className="Home--container">*/}
      {/*    <h1 className={'uppercase'}>*/}
      {/*        <Translate value="pricing" />*/}
      {/*    </h1>*/}
      {/*    <p>Text Text Text</p>*/}
      {/*</div>*/}
    </div>
  );
};
