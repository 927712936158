import React from "react";
import "./PrivacyPolicyDialog.css";
import {Dialog, DialogTitle, List, ListItem, useMediaQuery} from "@mui/material";
import {v4 as uuid} from "uuid";
import {Translate} from "react-i18nify";
import CloseIcon from '@mui/icons-material/Close';

export interface DialogProps {
    open: boolean;
    data: { [key: string]: string };
    onClose: () => void;
}

export const PrivacyPolicyDialog: React.FC<any> = (props: DialogProps) => {
    const { onClose, open, data } = props;
    const isMobile: boolean = useMediaQuery('(max-width: 960px)');

    React.useEffect(() => {
        if (!isMobile && open) {
            onClose();
        }
    }, [isMobile]);

    return (
        <Dialog onClose={onClose}
                open={open}
                className={'dialog'}>
            <DialogTitle className={'dialog--title'}>
                <Translate value="privacy-policy.title" />
                <CloseIcon className={'close-icon'}
                           onClick={onClose} />
            </DialogTitle>
            <List sx={{ pt: 0 }}>
                {Object.entries(data).map((translations: [string, string], index: number) => (
                    <ListItem  className={'list-item'}
                               disableGutters
                              key={uuid()}>
                        {index !== Object.entries(data).length - 1 &&
                            <>
                                <span className={'list-item--title'}>
                                    { index + 1 }. <Translate value={`privacy-policy.terms.${translations[0]}`} />
                                </span>
                                <span className={'list-item--text'}>
                                    <Translate value={`privacy-policy.terms.${translations[1]}`} />
                                </span>
                            </>
                        }
                        {index === Object.entries(data).length - 1 && // last item, customer support section
                            <>
                                <span className={'list-item--text'}>
                                    <Translate value={`privacy-policy.terms.${translations[0]}`} />
                                </span>
                                <u className={'list-item--text'}>
                                    { translations[1]}
                                </u>
                            </>
                        }
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}