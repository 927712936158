import React from "react";
import "./Navbar.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";
import { v4 as uuid } from "uuid";
import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";

export const Navbar: React.FC<any> = ({ activeLanguage, changeLanguage }) => {
  const [year, setYear] = React.useState(null);

  const languages: Array<string> = ["en", "ua"];
  const social: { [key: string]: JSX.Element } = {
    "https://www.facebook.com/AllaNadt": <FacebookIcon className={"icon"} />,
    "https://www.linkedin.com/in/alla-nadtochii-373432b3/": (
      <LinkedInIcon className={"icon"} />
    ),
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open: boolean = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>): void =>
    setAnchorEl(event.currentTarget);
  const handleClose = (): void => setAnchorEl(null);

  React.useEffect(() => {
    if (!year) {
      setYear(new Date().getFullYear() as any);
    }
  }, [year]);

  return (
    <nav className="Navbar">
      {/* regular menu */}

      <div className="Navbar-regular">
        <div className="localization">
          {languages.map((lang: string) => (
            <button
              className={"btn" + (activeLanguage === lang ? " active" : "")}
              onClick={() => changeLanguage(lang)}
              key={uuid()}
            >
              {lang.toUpperCase()}
            </button>
          ))}
        </div>
        <div className="social-media">
          {Object.entries(social)?.map(([url, element]) => (
            <a
              href={url}
              target="_blank"
              rel="noreferrer noopener"
              key={uuid()}
            >
              {element}
            </a>
          ))}
        </div>
        <div className="copyright">
          {`© ${year}`}
          <br />
          {"by Lirys"}
        </div>
      </div>

      {/* *********** */}
      {/* small resolution burger menu */}

      <div className={"Navbar-burger-wrapper"}>
        <Box
          className={"Navbar-burger"}
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip className={"Navbar-burger-btn"} title="menu">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? "menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}>
                {!open ? (
                  <MenuIcon fontSize="small" />
                ) : (
                  <CloseIcon fontSize="small" />
                )}
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>

        <Menu
          anchorEl={anchorEl}
          id="menu"
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          TransitionComponent={Fade}
          anchorOrigin={{ horizontal: -18, vertical: 50 }}
        >
          {languages.map((lang: string) => (
            <MenuItem className={"localization"} key={uuid()}>
              <button
                className={"btn" + (activeLanguage === lang ? " active" : "")}
                onClick={() => changeLanguage(lang)}
              >
                {lang.toUpperCase()}
              </button>
            </MenuItem>
          ))}
          {Object.entries(social)?.map(([url, element]) => (
            <MenuItem className={"social-media"} key={uuid()}>
              <a href={url} target="_blank" rel="noreferrer noopener">
                {element}
              </a>
            </MenuItem>
          ))}
        </Menu>
      </div>

      {/* *********** */}
    </nav>
  );
};
