import React, {useEffect} from "react";
import "./AboutMe.css";
import {Translate} from "react-i18nify";
import {v4 as uuid} from "uuid";
import BookIcon from "@mui/icons-material/Book";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import SpaIcon from "@mui/icons-material/Spa";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import DinnerDiningIcon from "@mui/icons-material/DinnerDining";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";

export const AboutMe: React.FC<any> = () => {
    const icons: { [key: string]: JSX.Element } = {
        'blogger': <BookIcon/>,
        'uata-eata': <CardMembershipIcon/>,
        'ata': <SpaIcon/>,
        'ed': <DinnerDiningIcon/>,
        'npa': <LoyaltyIcon/>,
        'cbt': <SupervisedUserCircleIcon/>
    };
    const entries: Array<[string, JSX.Element]> = Object.entries(icons);

    useEffect(() => {
    }, []);

    return (
        <div className={'AboutMe'}>
            <ul>
                {entries.map((entry: [string, JSX.Element]) => (
                    <div key={uuid()}
                         className={'list-item'}>
                        <div className={'icon-container'}>
                            { entry[1] }
                        </div>
                        <li>
                            <Translate value={`about-me.${entry[0]}`} />
                        </li>
                    </div>
                ))}
            </ul>
            <br/>
            <p>
                <Translate value={'about-me.trainings'} />
            </p>
        </div>
    )
}
