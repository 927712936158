import React from "react";
import "./ContactMe.css";
import {translate, Translate} from "react-i18nify";
import {
    Accordion, AccordionDetails, AccordionSummary,
    Button,
    FormControl,
    FormGroup,
    TextField, Typography
} from "@mui/material";
import {NavigateFunction, useNavigate} from "react-router-dom";
import emailjs from "emailjs-com";
import {confirmed$, Popup} from "../Popup/Popup";
import {Subscription} from "rxjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import {PrivacyPolicyDialog} from "../PrivacyPolicyDialog/PrivacyPolicyDialog";
import {v4 as uuid} from "uuid";

export const ContactMe: React.FC<any> = () => {
    const btnText: string = translate('contact-us.submit');
    const message: string = translate('contact-us.confirmation-body');

    const privacyPolicyTranslations: { [key: string]: string } = {
        'general': 'general-desc',
        'data-usage': 'data-usage-desc',
        'data-access': 'data-access-desc',
        'data-security': 'data-security-desc',
        'privacy-policy-changes': 'privacy-policy-changes-desc',
        'support': 'support@allanadtochii.com'
    };

    const navigate: NavigateFunction = useNavigate();

    const [isInvalid, setIsInvalid] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [expanded, setExpanded] = React.useState<string>('');
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [formData, setFormData] = React.useState({
        email: '',
        mobile: '',
        message: ''
    });

    const handleChange = (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => setExpanded(isExpanded ? panel : '');

    const navigateToUrl = (): void => navigate('/');
    const handleDialogOpen = () => setDialogOpen(true);
    const handleDialogClose = () => setDialogOpen(false);

    const updateState = (value: string, key: string): void => {
        value = value.trim();

        if (key === 'mobile') {
            const matched = value.match('^([0|\\+[0-9]{3})?([0-9][0-9]{1,9})$') || [];

            setFormData({ ...formData, [key]: matched?.[2] || '' });
            return;
        }

        setFormData({ ...formData, [key]: value });
    }

    const clearForm = (): void => setFormData({
        email: '',
        mobile: '',
        message: ''
    });

    const sendEmail = (): void => {
        setLoading(true);
        const mobileText: string = !!formData.mobile ? `, мобільний - ${formData.mobile}` : '';
        const templateParams: { message: string } = {
            message: `Повідомлення з сайту "alla.nadtochii". \nEmail - ${formData.email}${mobileText}. \nПовідомлення: ${formData.message}`
        };

        emailjs
            .send('service_5lyoedi', 'template_erwexld', templateParams, '6-bEny81ef7z5OoQn')
            .then((): void => {
                setLoading(false);
                clearForm();
                setIsInvalid(true);
            }, (error): void => {
                console.error(error.text);
                setIsInvalid(true);
            });
    }

    const subscriptionRef: React.MutableRefObject<Subscription> = React.useRef(new Subscription());

    React.useEffect(() => {
        subscriptionRef.current = confirmed$.subscribe(sendEmail);

        setIsInvalid(!formData?.email || !formData?.message);

        return () => subscriptionRef.current.unsubscribe();
    }, [formData]);

    return (
        <div className="ContactMe">
            <div className={'ContactMe--navigation'}>
                <Button className={'back-btn btn'}
                        onClick={navigateToUrl}>
                    <KeyboardBackspaceIcon className={'btn-icon'} />
                    <Translate value="back" />
                </Button>
            </div>
            <div className={'ContactMe--form-container'}>
                <form autoComplete="off">
                    <FormGroup className={'ContactMe--form-container--form-group'}>
                        <FormControl className={'ContactMe--form-container--form-control'}>
                            <TextField aria-describedby="helper-text"
                                       required
                                       value={formData.email}
                                       onChange={(e): void => updateState(e.target.value, 'email')}
                                       label={translate('contact-us.email')}
                                       id="outlined-required"
                                       type={'text'}
                                       helperText={translate('contact-us.email-desc')} />
                        </FormControl>
                        <FormControl className={'ContactMe--form-container--form-control'}>
                            <TextField label={translate('contact-us.mobile')}
                                       inputProps={{ maxLength: 13 }}
                                       value={`+380${formData.mobile.substring(1)}`}
                                       onChange={(e): void => updateState(e.target.value, 'mobile')}
                                       id="outlined-mobile-input"
                                       type={'text'} />
                        </FormControl>
                        <FormControl className={'ContactMe--form-container--form-control'}>
                            <TextField label={translate('contact-us.message')}
                                       id="outlined-multiline-flexible"
                                       required
                                       value={formData.message}
                                       onChange={(e): void => updateState(e.target.value, 'message')}
                                       minRows={4}
                                       multiline
                                       autoComplete={'off'} />
                        </FormControl>
                        {/* button with confirming popup */}
                        <Popup isInvalid={isInvalid}
                               btnText={btnText}
                               message={message}
                               isLoading={loading}
                        />
                    </FormGroup>
                </form>
            </div>

            <div className={'ContactMe--dialog-section'}>
                <Button variant="outlined"
                        onClick={handleDialogOpen}>
                    <Translate value="privacy-policy.title" />
                </Button>

                <PrivacyPolicyDialog open={dialogOpen}
                                     onClose={handleDialogClose}
                                     data={privacyPolicyTranslations}
                />
            </div>

            <Accordion expanded={expanded === 'privacy-policy'}
                       onChange={handleChange('privacy-policy')}
                       className={'ContactMe--panel'}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                  aria-controls="privacy-policy-bh-content"
                                  id="privacy-policy-bh-header"
                >
                    <Typography sx={{ width: '100%', flexShrink: 0 }}>
                        <Translate value="privacy-policy.title" />
                    </Typography>
                    {/*<Typography sx={{ color: 'text.secondary' }}>Secondary text</Typography>*/}
                </AccordionSummary>
                <AccordionDetails>
                    {Object.entries(privacyPolicyTranslations).map((translations: [string, string], index: number) => (
                        <Typography key={uuid()}>
                            {index !== Object.entries(privacyPolicyTranslations).length - 1 &&
                                <>
                                <span className={'list-item--title'}>
                                    { index + 1 }. <Translate value={`privacy-policy.terms.${translations[0]}`} />
                                </span>
                                    <span className={'list-item--text'}>
                                    <Translate value={`privacy-policy.terms.${translations[1]}`} />
                                </span>
                                </>
                            }
                            {index === Object.entries(privacyPolicyTranslations).length - 1 && // last item, customer support section
                                <>
                                <span className={'list-item--text'}>
                                    <Translate value={`privacy-policy.terms.${translations[0]}`} />
                                </span>
                                    <u className={'list-item--text'}>
                                        { translations[1]}
                                    </u>
                                </>
                            }
                        </Typography>
                    ))}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
