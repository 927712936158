import React from "react";
import "./EducationStepper.css";
import {v4 as uuid} from "uuid";
import {translate, Translate} from "react-i18nify";
import {StepIconProps, styled, Box, Stepper, Step, StepLabel, Typography} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import CheckIcon from "@mui/icons-material/Check";

interface IStep {
    label: string;
    caption: string;
}

export const EducationStepper: React.FC<any> = () => {
    const steps: Array<IStep> = [
        {
            label: translate("education.university"),
            caption: translate("education.caption1")
        },
        {
            label: translate("education.university"),
            caption: translate("education.caption2")
        }
    ];

    const bgColor: string = '#CE82BB';
    const boxShadow: string = '0 4px 10px 0 rgba(0,0,0,.25)';

    const ColorLibStepIconRoot = styled('div')<{
        ownerState: { completed?: boolean; active?: boolean };
    }>(({ theme, ownerState }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 30,
        height: 30,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        ...(ownerState.active && {
            background: bgColor,
            boxShadow: boxShadow
        }),
        ...(ownerState.completed && {
            backgroundImage: bgColor
        }),
    }));

    const ColorLibStepIcon = (props: StepIconProps) => {
        const { active, completed, className } = props;

        const icons: { [index: string]: React.ReactElement } = {
            1: <CastForEducationIcon />,
            2: <SchoolIcon />
        };

        return (
            <ColorLibStepIconRoot
                ownerState={{ completed, active }}
                className={className}
            >
                {icons[String(props.icon)]}
            </ColorLibStepIconRoot>
        );
    };

    const translationKeys: Array<string> = [
        'sand-therapy',
        'ta',
        'cbt',
        'st',
        'emdr',
        'ppd',
        'mu-for-teenagers',
        'edi-3',
        'nutritiology',
        'game-host'
    ];

    return (
        <div className={'EducationStepper'}>
            <h4>
                <Translate value="education.higher-education" />
            </h4>
            <Box>
                <Stepper orientation="vertical">
                    {steps.map((step: IStep) => (
                        <Step active={true}
                              completed={false}
                              key={uuid()}>
                            <StepLabel StepIconComponent={ColorLibStepIcon}
                                       optional={
                                           <Typography variant="caption">
                                               {step.caption}
                                           </Typography>
                                       }
                            >
                                {step.label}
                            </StepLabel>
                            {/*<StepContent></StepContent>*/}
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <h4>
                <Translate value="education.additional-education" />
            </h4>
            <Box sx={{ maxWidth: 400 }}
                 className={'block'}>
                <ul>
                    {translationKeys.map((key: string) => (
                        <div key={uuid()}
                             className={'list-item'}>
                            <CheckIcon />
                            <li>
                                <Translate value={`education.${key}`} />
                            </li>
                        </div>
                    ))}
                </ul>
            </Box>
        </div>
    );
}