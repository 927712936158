import React from "react";
import "./GraphicContainer.css";
import {translate, Translate} from "react-i18nify";
import {Avatar, Button} from "@mui/material";
import {NavigateFunction, useNavigate} from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";

export const GraphicContainer: React.FC<any> = () => {
    const navigate: NavigateFunction = useNavigate();
    const navigateToUrl = (): void => navigate('/contact-me');

    return (
        <div className="GraphicContainer">
            <Avatar className={'avatar'}
                    alt={translate('full-name')}
                    src="/images/alla-nad.jpg">
                <Translate value="full-name" />
            </Avatar>
            <h1>
                <Translate value="full-name" />
            </h1>
            <h4>
                <Translate value={'role'} />
            </h4>
            <Button className={'contact-btn'}
                    onClick={navigateToUrl}>
                <EmailIcon className={'btn-icon'} />
                <Translate value="contact-me" />
            </Button>
        </div>
    );
};
