import React, { Component, Suspense } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { setTranslations, setLocale, translate } from "react-i18nify";
import en from "../src/data/i18/en.json";
import ua from "../src/data/i18/ua.json";
import { Navbar } from "./components/Navbar/Navbar";
import { GraphicContainer } from "./components/GraphicContainer/GraphicContainer";
import { Home } from "./components/Home/Home";
import { ContactMe } from "./components/ContactMe/ContactMe";
import { v4 as uuid } from "uuid";
import { CircularProgress } from "@mui/material";

export default class App extends Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      language: "en",
      pathsToShowNavbar: ["/", "/contact-me"],
    };
  }

  changeLanguage(language: "en" | "ua" = "en"): void {
    setLocale(language);
    this.setState({ language });
    document.title = translate("full-name");
  }

  componentDidMount(): void {
    const translations: { en: Object; ua: Object } = {
      en: JSON.parse(JSON.stringify(en)),
      ua: JSON.parse(JSON.stringify(ua)),
    };

    setTranslations(translations);
    this.changeLanguage();
  }

  render() {
    return (
      <HashRouter basename={window.location.pathname || " "}>
        <div className="App">
          {/*  navbar section  */}

          <Suspense
            fallback={
              <CircularProgress color="primary" size={25} thickness={7.5} />
            }
          >
            <Routes>
              {this.state.pathsToShowNavbar.map((path: string) => (
                <Route
                  key={uuid()}
                  path={path}
                  element={
                    <Navbar
                      className={"App--container navbar"}
                      activeLanguage={this.state.language}
                      changeLanguage={this.changeLanguage.bind(this)}
                    />
                  }
                />
              ))}
              <Route path={"*"} element={<></>} /> {/* don't show navbar */}
            </Routes>
          </Suspense>

          {/*  graphic container section  */}

          <Suspense
            fallback={
              <CircularProgress color="primary" size={25} thickness={7.5} />
            }
          >
            <Routes>
              <Route
                path={"/"}
                element={
                  <div className={"App--container graphic"}>
                    <GraphicContainer />
                  </div>
                }
              />
              {/* don't show graphic container */}
              <Route path={"*"} element={<></>} />
            </Routes>
          </Suspense>

          {/*  context section  */}

          <div className="App--container context">
            <Suspense
              fallback={
                <CircularProgress color="primary" size={25} thickness={7.5} />
              }
            >
              <Routes>
                <Route path={"/"} element={<Home />} />
                <Route path={"/contact-me"} element={<ContactMe />} />
                {/* don't show context */}
                <Route path={"*"} element={<></>} />
              </Routes>
            </Suspense>
          </div>
        </div>
      </HashRouter>
    );
  }
}
